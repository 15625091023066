import React, { useEffect, useState } from "react";

//redux
import { connect, useDispatch, useSelector } from "react-redux";

//MUI
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { Cancel } from "@material-ui/icons";

//types
import { CLOSE_NEW_REDEEM_DIALOG } from "../../store/redeem/types";

//action
import { createAgencyRedeem, getSetting } from "../../store/redeem/action";
import { getProfile } from "../../store/admin/action";

const AgencyRedeemCreate = (props) => {
  const dispatch = useDispatch();

  const {
    dialog: open,
    dialogData,
    setting,
  } = useSelector((state) => state.redeem);
  const { seller } = useSelector((state) => state.admin);


  const agencyId = localStorage.getItem("agencyId");
  const [coin, setCoin] = useState();
  const [description, setDescription] = useState("");
  const [paymentGateway, setPaymentGateway] = useState("");
  const [errors, setError] = useState({
    description: "",
    paymentGateway: "",
    coin: "",
  });

  useEffect(() => {
    if (dialogData) {
      setDescription(dialogData.description);
      setPaymentGateway(dialogData.paymentGateway);
    }
  }, [dialogData]);

  useEffect(() => {
    props.getSetting();
    props.getProfile();
  }, []);

  useEffect(
    () => () => {
      setError({
        description: "",
        paymentGateway: "",
        coin: "",
      });
      setDescription("");
      setPaymentGateway("");
      setCoin("");
    },
    [open]
  );

  useEffect(() => {
    window.onbeforeunload = closePopup();
  }, []);

  const closePopup = () => {
    dispatch({ type: CLOSE_NEW_REDEEM_DIALOG });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    if (
      !description ||
      !paymentGateway ||
      seller?.withdrawableCoin < setting?.minRcoinForCashOutAgency ||
      !coin ||
      coin < setting?.minRcoinForCashOutAgency
    ) {
      const error = {};
      
      if (!coin) error.coin = "coin is required!";
      if (!description) error.description = "Description is required!";
      if (!paymentGateway)
        error.paymentGateway = "Payment Gateway is required!";
      if (seller?.withdrawableCoin < setting?.minRcoinForCashOutAgency)
        error.coin = "Insufficient Balance !";
      if (coin < setting?.minRcoinForCashOutAgency)
        error.coin = ` Maximum cashout amount ${setting?.minRcoinForCashOutAgency}`;
      return setError({ ...error });
    } else {
      
      const data = {
        agencyId: agencyId,
        description,
        paymentGateway: paymentGateway,
        coin: coin,
      };

      props.createAgencyRedeem(data);

      closePopup();
    }
  };

  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="responsive-dialog-title"
        onClose={closePopup}
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="responsive-dialog-title">
          <span className="text-dark font-weight-bold h4">Redeem Request</span>
        </DialogTitle>

        <IconButton
          style={{
            position: "absolute",
            right: 0,
          }}
        >
          <Tooltip title="Close">
            <Cancel className="text-dark" onClick={closePopup} />
          </Tooltip>
        </IconButton>
        <DialogContent>
          <div className="modal-body pt-1 px-1 pb-3">
            <div className="form-group d-flex align-items-center">
              <label className="text-dark mb-2">Current Balance :</label>
              <h5 className="text-dark ms-2">
                {seller?.withdrawableCoin ? seller?.withdrawableCoin : 0}
              </h5>
            </div>
            <div className="form-group d-flex align-items-center">
              <label className="text-dark mb-2">
                Min Coin For Cash Out :
              </label>
              <h5 className="text-dark ms-2">
                {" "}
                {setting?.minRcoinForCashOutAgency
                  ? setting?.minRcoinForCashOutAgency
                  : 0}
              </h5>
            </div>
            <div className="d-flex flex-column">
              <form>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="text-dark mb-2">coin</label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="coin"
                        value={coin}
                        onChange={(e) => {
                          setCoin(e.target.value);
                          if (!e.target.value) {
                            return setError({
                              ...errors,
                              coin: "coin is Required!",
                            });
                          } else {
                            return setError({
                              ...errors,
                              coin: "",
                            });
                          }
                        }}
                      />
                      {errors.coin && (
                        <div className="ml-2 mt-1">
                          {errors.coin && (
                            <div className="pl-1 text__left">
                              <span className="text-red">{errors.coin}</span>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12 mt-2">
                    <div className="form-group">
                      <label className="text-dark mb-2">Description</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Description"
                        value={description}
                        onChange={(e) => {
                          setDescription(e.target.value);
                          if (!e.target.value) {
                            return setError({
                              ...errors,
                              description: "Description is Required!",
                            });
                          } else {
                            return setError({
                              ...errors,
                              description: "",
                            });
                          }
                        }}
                      />
                      {errors.description && (
                        <div className="ml-2 mt-1">
                          {errors.description && (
                            <div className="pl-1 text__left">
                              <span className="text-red">
                                {errors.description}
                              </span>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12 mt-3">
                    <div className="form-group">
                      <label className="text-dark mb-2">Payment Gateway</label>
                      <select
                        class="form-select form-control"
                        aria-label="Default select example"
                        value={paymentGateway}
                        // placeholder="Select Payment Method... "
                        onChange={(e) => {
                          setPaymentGateway(e.target.value);
                          if (!e.target.value) {
                            return setError({
                              ...errors,
                              paymentGateway: "Payment Gateway is required!",
                            });
                          } else {
                            return setError({
                              ...errors,
                              paymentGateway: "",
                            });
                          }
                        }}
                      >
                        <option value="" disabled selected>
                          Select Payment Method...
                        </option>
                        {setting?.paymentGateway?.map((item, index) => {
                          return (
                            <>
                              <option value={item} key={index}>
                                {item}
                              </option>
                            </>
                          );
                        })}
                      </select>
                      {errors.paymentGateway && (
                        <div className="ml-2 mt-1">
                          {errors.paymentGateway && (
                            <div className="pl-1 text__left">
                              <span className="text-red">
                                {errors.paymentGateway}
                              </span>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  {errors.balance && (
                    <div className="ml-2 mt-1">
                      {errors.balance && (
                        <div className="pl-1 text__left mt-3">
                          <span className="text-red">{errors.balance}</span>
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div className="mt-5">
                  <button
                    type="button"
                    className="btn btn-outline-info ml-2 btn-round float__right icon_margin"
                    onClick={closePopup}
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-round float__right btn-danger"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default connect(null, {
  createAgencyRedeem,
  getSetting,
  getProfile,
})(AgencyRedeemCreate);
